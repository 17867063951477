import React from 'react';
import FormLine from 'src/components/design-system/form/line';
import FormSection from 'src/components/design-system/form/section';
import Toggle from 'src/components/design-system/form/toggle';
import P from 'src/components/design-system/p';
import ErrorAlert from 'src/components/design-system/alerts/error';
import Link from 'src/components/design-system/link';

const ConsentFormFragment = ({ formData, setFormData, errors }) => {
  return (
    <div className="mt-6 sm:mt-5">
      {errors && errors.reglement && (
        <ErrorAlert className="mt-4" message={errors.reglement} />
      )}

      <FormLine label="Règlement intérieur" htmlFor="reglement" isRequired>
        <div className="flex">
          <Toggle
            id="reglement"
            checked={formData.reglement}
            onChange={(reglement) => {
              setFormData({ ...formData, reglement });
            }}
          />
          <div className="ml-4 text-sm">
            <P>
              Oui, j'ai pris connaissance du{' '}
              <Link href="/reglementinterieurv2.3.pdf" newTab>
                règlement intérieur
              </Link>{' '}
              et du{' '}
              <Link href="/guide-parents-juillet-2020.pdf" newTab>
                guide des parents
              </Link>{' '}
              disponibles au bassin ou téléchargeables sur notre site. Je
              m'engage à m'y conformer pour assurer le bon déroulement de
              l'activité.
            </P>
          </div>
        </div>
      </FormLine>

      {errors && errors.cnil && (
        <ErrorAlert className="mt-4" message={errors.cnil} />
      )}

      <FormLine label="Traitement des données" htmlFor="cnil" isRequired>
        <div className="flex">
          <Toggle
            id="cnil"
            checked={formData.cnil}
            onChange={(cnil) => {
              setFormData({ ...formData, cnil });
            }}
          />
          <div className="ml-4 text-sm">
            <P>
              Je comprends et j'accepte que les informations recueillies sur ce
              formulaire soient enregistrées dans un fichier informatisé par
              l'association{' '}
              <em className="font-semibold not-italic">l'enfant d'eau</em>, afin
              de permettre le traitement de mon dossier d'inscription.
            </P>
            <P>
              Les informations renseignées ne serviront qu'au secretariat de
              l'association. Elles ne seront pas communiquées à des tiers pour
              des fins commerciales.
            </P>
            <P>
              Vous bénéficiez d’un droit d’accès et de rectification aux
              informations qui vous concernent.
            </P>
            <P>
              Si vous estimez, après nous avoir contactés, que vos droits
              «Informatique et Libertés» ne sont pas respectés, vous pouvez
              adresser une réclamation à la CNIL.
            </P>
          </div>
        </div>
      </FormLine>
    </div>
  );
};

export default ConsentFormFragment;
