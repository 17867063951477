import React from 'react';
import classnames from 'classnames';

import H2 from 'src/components/design-system/h2';

const Header = ({ className, children, title, ...otherProps }) => (
  <div className={classnames('bg-white pt-2 ', className)} {...otherProps}>
    <H2>{title}</H2>
    {children && <p class="mt-1 text-sm leading-5 text-gray-500">{children}</p>}
  </div>
);

export default Header;
