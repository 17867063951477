import React from 'react';
import FormLine from 'src/components/design-system/form/line';
import FormSection from 'src/components/design-system/form/section';
import Toggle from 'src/components/design-system/form/toggle';
import P from 'src/components/design-system/p';

const ReducFormFragment = ({ formData, setFormData, errors }) => {
  return (
    <div className="mt-6 sm:mt-5">
      <FormSection>Tarif réduit</FormSection>
      <FormLine label="Réduction" help="" htmlFor="reduc">
        <div className="flex flex-row">
          <Toggle
            id="reduc"
            checked={formData.reduc}
            onChange={(reduc) => {
              setFormData({ ...formData, reduc });
            }}
          />
          <div className="ml-4 text-sm">
            <P>
              L’association propose un tarif réduit (60% du tarif plein pour le
              prix par enfant), sous condition de ressources (quotient familial
              inférieur à 548 euros) ou pour les enfants en situation de
              handicap.
            </P>
            <P>
              En outre nous invitons les parents d’enfants en situation de
              handicap à nous contacter par email s’ils ont des demandes
              particulières, nous tâcherons d’y répondre du mieux possible.
            </P>
          </div>
        </div>
      </FormLine>
    </div>
  );
};

export default ReducFormFragment;
