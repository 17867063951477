import qs from 'qs';
import React, { useEffect } from 'react';

import { withGuard } from 'src/components/admin/guard';
import { withConfigGuard } from 'src/components/configuration';
import Card from 'src/components/design-system/card';
import { LoadingMany } from 'src/components/design-system/loading';
import InformationsReinscription from 'src/components/inscription/infos-reinscription';
import Layout from 'src/components/layouts/content';
import * as ContentDesign from 'src/components/layouts/content/theme';
import SEO from 'src/components/seo';
import routes from 'src/config/routes';
import useAxios, { useAxiosNow } from 'src/hooks/axios/useAxios';
import { navigate } from 'gatsby';

const Reinscription = ({ userProfile, location, config }) => {
  const { id } = location.search ? qs.parse(location.search.slice(1)) : {};
  const { requestState: requestStateAvailableCreneaux } = useAxiosNow({
    url: routes.getAvailableCreneaux(id),
  });

  const { requestState: requestStateFamille, load: loadFamille } = useAxios({
    url: routes.userFamille(),
  });

  useEffect(() => {
    loadFamille().then(({ data } = {}) => {
      if (!data || !data.data) {
        navigate('/famille');
      }

      if (
        !config.reinscription_ouverte &&
        config.reinscription_ca_ouverte &&
        !data.data.is_membreCA
      ) {
        navigate('/famille');
      }

      if (
        config.reinscription_ouverte &&
        !config.inscription_ouverte &&
        !data.data.isLastSeasonMember
      ) {
        navigate('/famille');
      }
    });
  }, []);

  return (
    <Layout userProfile={userProfile}>
      <SEO title="Espace adhérent - réinscription" />
      <ContentDesign.H2>Réinscription – étape 1 / 4</ContentDesign.H2>
      <LoadingMany
        requestStates={[requestStateFamille, requestStateAvailableCreneaux]}
        errorComponent={() => (
          <ContentDesign.P>Une erreur s'est produite.</ContentDesign.P>
        )}
      >
        {([rsFamille, rsCreneaux]) => {
          const { data: famille } = rsFamille.data;
          const {
            data: {
              data: { creneaux, sessions, prebook },
            },
          } = rsCreneaux;

          return (
            <Card className="mt-4">
              <InformationsReinscription
                famille={famille}
                creneaux={creneaux}
                sessions={sessions}
                prebook={prebook}
              />
            </Card>
          );
        }}
      </LoadingMany>
    </Layout>
  );
};

export default withGuard(
  withConfigGuard({
    redirectTo: '/famille',
    shouldRedirect: (config) =>
      !config.reinscription_ouverte && !config.reinscription_ca_ouverte,
  })(Reinscription)
);
