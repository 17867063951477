import React from 'react';
import qs from 'qs';

import ErrorAlert from 'src/components/design-system/alerts/error';
import Submit from 'src/components/design-system/buttons/submit';
import FormFooter from 'src/components/design-system/form/footer';
import useForm from 'src/hooks/useForm';
import routes from 'src/config/routes';

import ConsentFormFragment from './formFragments/consent';
import EnfantsForm from './formFragments/enfants';
import EnfantsInscritsForm from './formFragments/enfantsInscrits';
import ReducFormFragment from './formFragments/reduc';

import Header from './header';

const defaultFormValue = (famille, prebook = {}) => ({
  reduc: false,
  reglement: false,
  cnil: false,

  famille_id: famille.id,
  mail_principal: famille.mail_principal,

  enfants_sauvegarde: famille.enfants || [],
  enfants: [
    /* 
          nom: '',
          prenom: '',
          sexe: '',
          creneaux_id: undefined
          sessions_id: undefined,
         */
  ],
  ...prebook,
});

const InformationReinscription = ({ creneaux, sessions, famille, prebook }) => {
  const { formData, setFormData, requestState, submitForm } = useForm({
    initialValue: defaultFormValue(famille, prebook),
    axiosConfig: {
      method: 'POST',
      url: routes.prebookReinscription(),
    },
    redirectUrl: (data) =>
      `/famille/recap?${qs.stringify({ id: data.data.id })}`,
  });

  const errors =
    requestState.status === 'error' ? requestState.error.errors : {};

  return (
    <form onSubmit={submitForm}>
      {requestState.status === 'error' && (
        <ErrorAlert
          className="mt-4"
          message={
            errors._global ||
            'Certaines informations sont manquantes ou invalides.'
          }
        />
      )}

      <Header title="Enfants et créneaux" />

      <EnfantsInscritsForm
        formData={formData}
        setFormData={setFormData}
        requestState={requestState}
        creneaux={creneaux}
        sessions={sessions}
      />

      <EnfantsForm
        formData={formData}
        setFormData={setFormData}
        requestState={requestState}
        creneaux={creneaux}
        sessions={sessions}
      />

      <ReducFormFragment
        formData={formData}
        setFormData={setFormData}
        errors={errors}
      />

      <Header
        title="Mentions légales"
        className="mt-8 border-t border-gray-200"
      />

      <ConsentFormFragment
        formData={formData}
        setFormData={setFormData}
        errors={errors}
      />

      <FormFooter>
        <Submit type="submit" isLoading={requestState.status === 'loading'}>
          Valider
        </Submit>
      </FormFooter>
    </form>
  );
};

export default InformationReinscription;
